import React from "react";
import clsx from "clsx";

import styles from "./skeleton.module.css";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  bg?: "light" | "dark";
  className?: string;
}
const Skeleton = ({ bg = "light", className, ...rest }: Props) => {
  return (
    <div
      className={clsx(
        styles.root,
        {
          [styles.light]: bg === "light",
          [styles.dark]: bg === "dark",
        },
        className,
      )}
      {...rest}
    />
  );
};

export default Skeleton;
