import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import { MONTHS_AND_WEEK_DAYS } from "./constants";

dayjs.extend(updateLocale);

const getTranslatedTermsDayJS = (lang: string) => {
  // Fallback to English if the language doesn't exist
  const translations =
    MONTHS_AND_WEEK_DAYS[lang as keyof typeof MONTHS_AND_WEEK_DAYS] ||
    MONTHS_AND_WEEK_DAYS.en;

  return {
    months: translations.months.full,
    monthsShort: translations.months.short,
    weekdays: translations.weekDays.full,
    weekdaysShort: translations.weekDays.short,
  };
};

export const setCalendarTranslations = (lang: string) => {
  const currentLocale = dayjs.locale();

  dayjs.updateLocale(currentLocale, getTranslatedTermsDayJS(lang));
};
