import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/app/app-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/components/footer/footer-skeleton.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/components/footer/icons/cards.svg");
;
import(/* webpackMode: "eager" */ "/usr/src/app/components/footer/icons/trust.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/components/footer/menu.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/components/logo/logo.svg");
;
import(/* webpackMode: "eager" */ "/usr/src/app/components/navbar/desktop.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/components/navbar/mobile.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/components/navbar/user-account/user-account.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/components/skeleton/skeleton.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/components/smart-app-banner/content.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/images/app-store.svg");
;
import(/* webpackMode: "eager" */ "/usr/src/app/images/google-play.svg");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/usr/src/app/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/font/google/target.css?{\"path\":\"app/[lang]/layout.tsx\",\"import\":\"Manrope\",\"arguments\":[{\"weight\":[\"400\",\"600\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-manrope\"}],\"variableName\":\"manrope\"}");
