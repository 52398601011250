import {
  LANGUAGE_FERRY_COMPANIES_TERM_COMBINATIONS,
  LANGUAGE_FERRY_TERM_COMBINATIONS,
} from "@/constants/language";

const checkPathname = (routes: string[], pathname: string) => {
  return routes.some((route) => pathname.includes(route));
};

const isMobileAppPage = (pathname: string) => {
  return pathname.includes("/app");
};

const isFerryCompaniesPage = (pathname: string) => {
  const ferryCompaniesTerms = Object.values(
    LANGUAGE_FERRY_COMPANIES_TERM_COMBINATIONS,
  );
  return checkPathname(ferryCompaniesTerms, pathname);
};

const extractAndTransformPath = (
  pathname: string,
  language: string,
  termCombinations: { [key: string]: string },
) => {
  const terms = Object.values(termCombinations);

  const matchedTerm = terms.find((term) => pathname.includes(`/${term}/`));

  if (matchedTerm) {
    const remainingPath = pathname.split(`/${matchedTerm}/`)[1] || "";
    return `/${language}/${termCombinations[language]}/${remainingPath}`;
  }

  return `/${language}/${termCombinations[language]}`;
};

export const getTargetPath = ({
  pathname,
  language,
}: {
  pathname: string;
  language: string;
}) => {
  if (isMobileAppPage(pathname)) {
    return `/${language}/app`;
  }

  if (isFerryCompaniesPage(pathname)) {
    return extractAndTransformPath(
      pathname,
      language,
      LANGUAGE_FERRY_COMPANIES_TERM_COMBINATIONS,
    );
  }

  return extractAndTransformPath(
    pathname,
    language,
    LANGUAGE_FERRY_TERM_COMBINATIONS,
  );
};
