"use client";

import useSWR, { BareFetcher } from "swr";
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import Cookies from "js-cookie";
import store from "@/store";
import { loadState } from "@/store/persistent-storage";
import { actions as RoutesActions } from "@/store/routes";
import { actions as SearchMaskActions } from "@/store/searchMask";
import { setCalendarTranslations } from "@/utils/calendar-translations";
import { fetcher } from "@/utils/fetch-utils";

interface ConfigurationData {
  results: {
    data: {
      partnerCodeCookie: Record<string, number>;
    };
  };
}

export default function AppProvider({
  children,
  lang,
}: {
  children: React.ReactNode;
  lang: string;
}) {
  setCalendarTranslations(lang);

  const { data } = useSWR(
    "/api/v1/ferries/configuration?params[]=partnerCodeCookie",
    fetcher as BareFetcher<ConfigurationData>,
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const partnerCode = urlParams.get("partnerCode");
    const ref = urlParams.get("ref");

    const code = partnerCode || ref;

    if (!code || !data?.results?.data?.partnerCodeCookie) {
      return;
    }

    const expirationPeriod = data?.results?.data?.partnerCodeCookie[code] || 30;

    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + expirationPeriod);

    Cookies.set("partnerCode", code, {
      expires: expirationDate,
    });
  }, [data]);

  useEffect(() => {
    const initializeState = () => {
      const persistedState = loadState();

      if (persistedState?.routes) {
        Object.entries(persistedState.routes).forEach(([key, value]) => {
          // Dynamically find and call the corresponding action
          const actionName =
            `change${key.charAt(0).toUpperCase()}${key.slice(1)}` as keyof typeof RoutesActions;
          const action = RoutesActions[actionName];

          if (typeof action === "function") {
            store.dispatch(action(value as never));
          }
        });
      }

      if (persistedState?.searchMask) {
        Object.entries(persistedState.searchMask).forEach(([key, value]) => {
          // Dynamically find and call the corresponding action
          const actionName =
            `change${key.charAt(0).toUpperCase()}${key.slice(1)}` as keyof typeof SearchMaskActions;
          const action = SearchMaskActions[actionName];

          if (typeof action === "function") {
            store.dispatch(action(value as never));
          }
        });
      }
    };

    initializeState();
  }, []);

  return <Provider store={store}>{children}</Provider>;
}
