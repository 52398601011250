import { useEffect, useState } from "react";
import { getCurrencyData } from "@/data/user/queries";
import Cookies from "js-cookie";

const useCurrencies = () => {
  const [defaultCurrency, setDefaultCurrency] = useState("");
  const [availableCurrencies, setAvailableCurrencies] = useState<string[]>([]);

  useEffect(() => {
    const getData = async () => {
      const { availableCurrencies, defaultCurrency } = await getCurrencyData();
      setAvailableCurrencies(availableCurrencies);
      setDefaultCurrency(defaultCurrency);

      const currencyStorage = JSON.parse(
        sessionStorage.getItem("currency-storage") ?? "{}",
      );
      const preferredCurrency = currencyStorage.iso_currency as string;

      const currency = preferredCurrency || defaultCurrency;

      const currencyDetails = { iso_currency: currency };
      sessionStorage.setItem(
        "currency-storage",
        JSON.stringify(currencyDetails),
      );

      Cookies.set("currency", currency);
    };

    getData();
  }, []);

  return { defaultCurrency, availableCurrencies };
};

export default useCurrencies;
